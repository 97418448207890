import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
import UnsubscribeForm from "@components/form/Form.Unsubscribe.jsx"
// Utility imports
import SEO from '@components/utility/SEO.jsx';



const Index = () => (
	<Layout pageClassName="page-unsubscribe">
		<SEO title='Unsubscribe | Aurinia Alliance&reg;' description="We're sorry to see you go. Unsubscribe here if you would no longer like to receive updates and information about Aurinia Alliance&reg;." />
		<div className="wrapper unsubscribe-content">
			<h1>You're Leaving?</h1>
			<h2 className="blue">We're sorry to see you go</h2>
			<p>Simply enter your email address below to unsubscribe from marketing communications from Aurinia Alliance.</p>
			<p>* = required field</p>			
			<UnsubscribeForm></UnsubscribeForm>
		</div>
		
	</Layout>
);

export default Index;
