import useForm from './useForm.js';
import React, { useCallback, useEffect } from "react";
import validate from "../../utils/util-validator";
import ruleSet from './ruleSet.js';
import { isEvokeScreenshooting, getEvokeScreenshotState } from '@src/helper';
import { navigate } from "gatsby";
// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint } from '@src/helper';

// Content Imports


const Unsubscribe = () => {
    const preventFocus = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            field.addEventListener('focus', (e) => {
                field.blur();
            });
        });
    }
    useEffect(()=>{
        const isEDMode = typeof window !== 'undefined' ? window.location.href.indexOf('ed=1') > 0 : false;
        if(isEDMode || isEvokeScreenshooting()){
            preventFocus();
        }
	},[])

    const success = useCallback((vals) => {
        const submitButton = document.querySelector("button[type='submit']");
        submitButton.classList.add('button--submitting');
        fetch(getApiEndpoint() + 'psp/unsub', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            submitButton.classList.remove('button--submitting');
            if (r.success) {
                gtmFormSuccess();
                gtmFormFieldCompletionSubmitted();
                navigate('/unsubscribe-confirmation',
                    {
                        state: {
                            values: vals, 
                            token: r.data.token
                        }  
                    }
                );
            } else {
                navigate('/500');
            }
        })
        .catch((err) => {
            submitButton.classList.remove('button--submitting');
            navigate('/500');
        })
    }, [])
    
    const handleAllChecked = (event)=>{
        event.persist();
        if(event.target.name === 'unsubscribeAll'){
            setValues(values => ({ ...values, [event.target.name]: event.target.checked,  unsubscribeTreatment: event.target.checked, unsubscribeDiseaseAwareness: event.target.checked, unsubscribeCorporate: event.target.checked}));
        }else{
            handleChange(event);
            setValues(values => ({ ...values, unsubscribeAll: false}));
        }
    }

    const valueChangeCallback = useCallback((vals) => {
        if(vals.unsubscribeTreatment && vals.unsubscribeDiseaseAwareness && vals.unsubscribeCorporate && vals.unsubscribeAll === false){
            setValues(values => ({ ...values, unsubscribeAll: true}));
        }
    }, [])
        
    //GTM
    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Form Submission',
            'Event_Category': 'Form Submissions', 
            'Event_Action': 'Unsubscribe',
            'Event_Label': 'Complete'
        });
    }
    const gtmFormFieldError = (errors) => {
        if ( errors ) {
            for(var error in errors){
                const selector = ".error--" + error;
                if(document.querySelector(selector)){
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'Form Errors',
                        'Event_Category': 'Validation Errors', 
                        'Event_Action': 'Unsubscribe',
                        'Event_Label': error + '/' + errorText
                    });
                }
                
            }
        }   
    }
    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            if(!field.getAttribute('data-has-ga-listener')){
                field.setAttribute('data-has-ga-listener','true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function(e) {
        const field = e.target;
        let fieldInfo;
        switch(field.type){
            case ('radio'):
                fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            default:
                fieldInfo = field.name
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Field Completion',
            'Event_Category': 'Form Field Completion', 
            'Event_Action': 'Unsubscribe',
            'Event_Label': fieldInfo
        });
    }
    
    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--unsubscribe input').forEach( field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch(field.type){
                case ('radio'):
                    fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted', 
                'Event_Action': 'Unsubscribe',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(()=>{
        gtmFieldCompletion();
    })


    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues
    } = useForm(success, validate, ruleSet.unsub.rules, {source: 'aurinia_alliance_unsubscribe_2021', visitorId : getVisitorId()}, valueChangeCallback, 
    gtmFormFieldError);


    return (
        <form className="form--unsubscribe" onSubmit={handleSubmit}>
            <div className="single-col-input">
                <label>
                    Email Address*:
                    <input type="text" name="email" placeholder="e.g. jsmith@email.com" value={values.email || ''} onChange={handleChange} aria-invalid={errors.email ? true : false} maxLength={'50'}/>
                    {errors.email && (
                    <p className="error error--email">Please input a valid email address</p>
                )} 
                </label> 
            </div>
            <h3>Select any other marketing communications you’re no longer interested in receiving:</h3>
            <div className="checkbox-wrapper">
                <input name="unsubscribeTreatment" id="unsubscribeTreatmentCheckbox" type="checkbox" checked={values.unsubscribeTreatment || ''} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeTreatmentCheckbox">Information about treatment for lupus nephritis</label>
            </div>
            <div className="checkbox-wrapper">
                <input name="unsubscribeDiseaseAwareness" id="unsubscribeDiseaseAwarenessCheckbox" type="checkbox" checked={values.unsubscribeDiseaseAwareness || ''} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeDiseaseAwarenessCheckbox">Disease state awareness information</label>
            </div>
            <div className="checkbox-wrapper">
                <input name="unsubscribeCorporate" id="unsubscribeCorporateCheckbox" type="checkbox" checked={values.unsubscribeCorporate || ''} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeCorporateCheckbox">Corporate communications from Aurinia</label>
            </div>
            <div className="checkbox-wrapper">
                <input name="unsubscribeAll" id="unsubscribeAllCheckbox" type="checkbox" checked={values.unsubscribeAll || ''} onChange={handleAllChecked} />
                <label htmlFor="unsubscribeAllCheckbox">All Aurinia-affiliated communications</label>
            </div>
            <p className="note"><em>NOTE: If you are currently enrolled in Aurinia Alliance, you may still receive communications from your Nurse Case Manager or the program. If you have any questions about your enrollment, please call 1-833-AURINIA (1-833-287-4642).</em></p>
            <button id="UnsubscribeSubmit" type="submit" className="btn btn--purple button--unsubscribe">UNSUBSCRIBE</button>
        </form>
    )
}

export default Unsubscribe;
